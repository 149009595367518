const scrollUpBtn = () => {
  const buttonUp = document.querySelector(".js-button-up")
  const subscribeSection = document.querySelector(".section--subscribe")

  window.addEventListener("scroll", () => {
    if (window.scrollY > window.innerHeight) {
      buttonUp.classList.add("show-btn")
    } else {
      buttonUp.classList.remove("show-btn")
    }

    if (subscribeSection.offsetTop - window.innerHeight + 107 > window.scrollY) {
      buttonUp.classList.remove("white")
    } else {
      buttonUp.classList.add("white")
    }
  })

  buttonUp.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  })
}

export { scrollUpBtn }
