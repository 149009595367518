export function dragDrop() {
  const dropArea = document.querySelector(".order__input-file__wrapper")
  const dropAreaContainer = document.querySelector(".order__files-content")
  const form = document.querySelector(".form")
  const inputFile = document.querySelector("#file")
  const inputCount = document.querySelector("[data-file-number]")
  const inputCountMax = document.querySelector("[data-file-max]")
  const inputError = document.querySelector("[data-maxlength]")

  inputFile.addEventListener("change", handleChange)

  // Prevent default drag behaviors
  ;["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
    dropArea.addEventListener(eventName, preventDefaults, false)
    document.body.addEventListener(eventName, preventDefaults, false)
  })

  // Highlight drop area when item is dragged over it
  ;["dragenter", "dragover"].forEach((eventName) => {
    dropArea.addEventListener(eventName, highlight, false)
  })
  ;["dragleave", "drop"].forEach((eventName) => {
    dropArea.addEventListener(eventName, unhighlight, false)
  })

  // Handle dropped files
  dropArea.addEventListener("drop", handleDrop, false)

  function preventDefaults(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  function highlight(event) {
    dropArea.classList.add("highlight")
  }

  function unhighlight(event) {
    dropArea.classList.remove("highlight")
  }

  function handleDrop(event) {
    let dt = event.dataTransfer
    let transferFiles = dt.files
    const files = Object.keys(transferFiles).map((i) => transferFiles[i])

    event.target.value = ""

    addFiles(files)

    removeFiles()
  }

  function handleChange(event) {
    if (!event.target.files.length) {
      return
    }

    const files = Object.keys(event.target.files).map((i) => event.target.files[i])

    addFiles(files)

    event.target.value = ""

    removeFiles()
  }

  const removeFiles = () => {
    const btnRemove = document.querySelectorAll(".order__files-content__item-btn")

    btnRemove.forEach(($btn) => {
      $btn.addEventListener("click", (event) => {
        event.preventDefault()

        this.data.filter((file, i) => {
          if (
            file.name ==
            $btn.closest(".order__files-content-col").querySelector(".order__files-content__item-text").innerText
          ) {
            removeFile(i)
          }
        })

        inputCount.innerText = this.data.length

        $btn.closest(".order__files-content-col").remove()
      })
    })
  }

  const addFiles = (files) => {
    if (this.data.length >= 20) {
      return
    }

    this.data = this.data.concat(files)

    if (this.data.length > 20) {
      inputError.innerHTML = inputError.dataset.maxlength
    } else {
      inputError.innerHTML = ""
    }

    if (this.data.length >= 20) {
      this.data = this.data.slice(0, 20)
    }

    const murkup = this.data.map(
      (file) => `<div class="order__files-content-col">
                <div class="order__files-content__item">
                  <div class="order__files-content__item-text">${file.name}</div>
                    <button class="order__files-content__item-btn">
                        <svg class="icon icon-close">
                            <use href="icon/icons/icons.svg#close" />
                        </svg>
                    </button>
                </div>
              </div>`
    )

    dropAreaContainer.innerHTML = murkup.join("")

    inputCount.innerText = this.data.length
  }

  const removeFile = (index) => {
    this.data.splice(index, 1)
  }
}
