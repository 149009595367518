export function sendFetchService({ onSuccess, onError }) {
  if (!this.isValid) return console.log("Form is invalid. Invalid fields", this.invalidFields)

  const action = this.$form.getAttribute("action")
  const method = this.$form.getAttribute("method")
  const body = new FormData(this.$form)

  const fetch_options = { method }

  if (method.toUpperCase() !== "GET") {
    fetch_options.body = body
  }

  fetch(action, fetch_options)
    .then((data) => data.json())
    .then(onSuccess)
    .catch(onError)
}
