function checkbox() {
  const isChecked = this.$checkbox.checked

  if (!isChecked) {
    this.isValid = false
    return
  }

  this.isValid = true
}

export { checkbox }
