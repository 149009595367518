import { Cookies } from "./cookies"
import { ModalRoot } from "./modal-root"

export class ModalCookieSubmition extends ModalRoot {
  constructor(options) {
    super(options)

    this.$submit = this.$container?.querySelector(".js-cookies-submit")

    this.isSubmitted = JSON.parse(Cookies.getByName("cookies_submitted") ?? false)

    this.modalCookieInit()

    !this.isSubmitted && this.open()
  }

  modalCookieInit() {
    this.$submit?.addEventListener("click", this.#onSubmitClickHandle)
  }

  #onSubmitClickHandle = () => {
    Cookies.set("cookies_submitted", true)
    this.isSubmitted = true
    this.close()
  }
}
