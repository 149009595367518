const calculatePrice = ({ selectsInstances }) => {
  const $calculatePagesSelect = document?.querySelector("#pages")
  const $calculateTypeSelect = document?.querySelector("#type")
  const $dataTypes = document?.querySelector("[data-types]")
  const $dataCount = document?.querySelector("[data-count]")
  const $calculationItem = document?.querySelector(".calculation__item")
  const $dataTotal = document?.querySelector("[data-total]")
  const $dataCertification = document?.querySelector("[data-certification]")
  const $checkboxes = document?.querySelectorAll(".checkbox__input")

  const calculateTypes = () => {
    if ($calculateTypeSelect?.value == 0) {
      $dataTypes.innerText
    } else {
      if ($dataTypes) {
        $dataTypes.innerText = $calculateTypeSelect.value
      }
    }

    return +$dataTypes?.innerText
  }

  const calculatePages = () => {
    if ($calculatePagesSelect?.value == "") {
      $dataCount.innerText = 0
    } else {
      if ($dataCount) {
        $dataCount.innerText = $calculatePagesSelect?.value
      }
    }

    return +$dataCount?.innerText
  }

  const calculateTotal = () => {
    $dataTotal.innerText = `${calculatePages() * calculateTypes()} грн`
  }

  const calculationCheckbox = () => {
    $checkboxes.forEach((item) => {
      if (item.checked) {
        if ($dataCertification) {
          $dataCertification.innerText = item?.value
        }

        if (item.value >= 1 && $dataTotal) {
          $dataTotal.innerText = `${calculatePages() * calculateTypes() * Number(item.value)} грн`
        } else if ($dataTotal) {
          $dataTotal.innerText = `${calculatePages() * calculateTypes()} грн`
        }
      }
    })
  }

  $checkboxes.forEach((item) => {
    item.addEventListener("change", () => {
      $checkboxes.forEach((item) => {
        item.checked = false
      })

      item.checked = true
      calculationCheckbox()
    })
  })

  const calculationHandle = () => {
    calculateTotal()
    calculationCheckbox()
  }

  const currentSelects = selectsInstances.filter((select) => {
    return $calculationItem?.contains(select.$select)
  })

  currentSelects.forEach((select) => {
    select.calculationHandle = calculationHandle.bind(select)
  })
}

export { calculatePrice }
