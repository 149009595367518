import L from "leaflet"

const map = () => {
  const mapElements = document.querySelectorAll(".footer__address-item__location")
  const $contactItem = document.querySelector(".contacts")

  const maps = []

  var currentDomain = window.location.origin

  const customIcon = L.icon({
    iconUrl: `${currentDomain}/svg/location-red.svg`,
    iconSize: [40, 40]
  })

  mapElements.forEach((mapElement, index) => {
    const lat = parseFloat(mapElement.getAttribute("data-lat"))
    const lon = parseFloat(mapElement.getAttribute("data-lon"))
    const zoom = parseFloat(mapElement.getAttribute("data-zoom"))

    const map = L.map(mapElement.id).setView([lat, lon], zoom)

    maps.push(map)

    L.marker([lat, lon], {
      icon: customIcon
    }).addTo(map)

    if ($contactItem) {
      const mapContact = L.map(`mapContact${index + 1}`).setView([lat, lon], zoom)

      maps.push(map, mapContact)

      L.marker([lat, lon], {
        icon: customIcon
      }).addTo(mapContact)
    }
  })

  maps.forEach(($map) => {
    L.tileLayer(
      "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
      {
        attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
        maxZoom: 16
      }
    ).addTo($map)
  })
}

export { map }
