import { defaultModalOptions } from "./modal.default"
import { ScrollbarLocker } from "./scrollbar-locker"

export class ModalRoot {
  /**
   * @param { defaultModalOptions } options
   */
  constructor(options) {
    this.options = { ...defaultModalOptions, ...options }

    this.id = null

    this.$container =
      this.options.container instanceof HTMLElement
        ? this.options.container
        : document.querySelector(this.options.container)

    if (!this.$container) {
      return console.warn("Can not initialize modal with out container", this)
    }

    this.$currentOpenTrigger = null
    this.openTriggers = document.querySelectorAll(this.options.openTriggerSelector)

    this.isOpen = false

    this.closeTriggers = this.$container.querySelectorAll(this.options.closeTriggerSelector)

    this._onOpen = typeof this.options.onOpen === "function" && this.options.onOpen.bind(this)
    this._onClose = typeof this.options.onClose === "function" && this.options.onClose.bind(this)

    this.init()
  }

  init() {
    if (!this.$container) {
      return console.warn(
        `Modal can not be initialize because ${this.options.container} is ${!this.$container} in`,
        this
      )
    }

    if (!this.$container.id) {
      return console.warn(
        "Modal can not be initialize because container does not have required attribute [id] in",
        this
      )
    }

    this.id = this.$container.id.toLowerCase()

    this.openTriggers = [...this.openTriggers].filter(($button) => {
      const targetID = $button.dataset?.modalTarget?.toLowerCase()

      if (!targetID) {
        return console.warn(
          `The button has class ${this.options.openTriggerSelector} but does not have required attribute or value in [data-modal-target]`,
          $button
        )
      }

      if (this.id !== targetID) {
        return false
      }

      $button.addEventListener("click", this.#openBtnClickHandler)

      return true
    })

    this.closeTriggers.forEach(($button) => $button.addEventListener("click", this.#closeBtnClickHandler))

    this.$container.addEventListener("click", this.#containerClickHandler)
  }

  #windowKeyDownHandler = (event) => {
    if (event.code === "Escape") {
      this.close()
    }
  }

  #containerClickHandler = (event) => {
    if (event.target === event.currentTarget) {
      this.close()
    }
  }

  #openBtnClickHandler = (event) => {
    this.$currentOpenTrigger = event.currentTarget
    this.open()
  }

  #closeBtnClickHandler = () => {
    this.$currentOpenTrigger = null
    this.close()
  }

  set onOpen(fn) {
    this._onOpen = fn
  }

  get onOpen() {
    return this._onOpen
  }

  set onClose(fn) {
    this._onClose = fn
  }

  get onClose() {
    return this._onClose
  }

  open() {
    if (this.isOpen) {
      return
    }

    this.onOpen && this.onOpen(this)

    ScrollbarLocker.lock()

    this.$container.classList.add(this.options.openModalClass)
    this.openTriggers.forEach(($button) => $button.classList.add(this.options.activeOpenTriggerClass))

    window.addEventListener("keydown", this.#windowKeyDownHandler)

    this.isOpen = true
  }

  close() {
    if (!this.isOpen) {
      return
    }

    ScrollbarLocker.unLock()

    this.$container.classList.remove(this.options.openModalClass)
    this.openTriggers.forEach(($button) => $button.classList.remove(this.options.activeOpenTriggerClass))

    window.addEventListener("keydown", this.#windowKeyDownHandler)

    this.isOpen = false

    this.onClose && this.onClose(this)
  }
}
