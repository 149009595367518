import { minmaxLength } from "../lib/minmax-length"

function empty() {
  const isEmpty = this.$input.value !== ""
  const { isMinMaxLengthValid } = minmaxLength(this.$input)

  if (!isEmpty) {
    this.isValid = false
    return
  }

  if (!isMinMaxLengthValid) {
    this.isValid = false
    return
  }

  this.isValid = true
}

export { empty }
