import moment from "moment-timezone"

export function sendXmlReqService({ onSuccess, onError }) {
  if (!this.isValid) return console.log("Form is invalid. Invalid fields", this.invalidFields)

  const action = this.$form.getAttribute("action")
  const method = this.$form.getAttribute("method")
  const body = new FormData(this.$form)

  this.fields.forEach((field) => {
    console.log(field)

    if (field.type === "file") {
      console.log(field.data)

      for (let i = 0; i < field.data.length; i++) {
        const element = field.data[i]
        body.append(`files[${i}]`, element)
      }
    }
  })

  // === Ajax request
  const xhr = new XMLHttpRequest()
  xhr.open(method, action)

  // Set Accept header to application/json
  xhr.setRequestHeader("Accept", "application/json")

  xhr.onreadystatechange = () => {
    const nonWorkingTimeModal = document.querySelector("#non_working_time_modal")

    const ukraineTime = moment.tz("Europe/Kiev")

    const ukraineHour = ukraineTime.hour()
    const ukraineDay = ukraineTime.day()

    const isNonWorkingTime = ukraineHour >= 18 || ukraineHour < 9
    const isWeekends = ukraineDay === 6 || ukraineDay === 7

    if (isNonWorkingTime || isWeekends) {
      nonWorkingTimeModal.classList.add("show")
      return
    }

    if (xhr.status == 422) {
      let errors = xhr?.responseJSON?.errors

      if (errors) {
        for (let attribute in errors) {
          let message = errors[attribute][0]

          this.fields.forEach((field) => {
            const form = field.form.$form
            const formField = form.querySelector(".form-field")

            if (field.name === attribute) {
              formField.classList.add("invalid")
              field.addError(message)
            }
          })
        }
      }
    }

    if (xhr.status >= 400) {
      onError(xhr)
      return
    }

    onSuccess(xhr)
  }

  xhr.send(body)
}
