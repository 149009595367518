import "imask"

function mask() {
  const $elements = document.querySelectorAll("[type='tel']")

  const maskOptions = {
    mask: "+{38}(000)-00-00-000"
  }

  $elements.forEach(($element) => {
    const masks = new IMask($element, maskOptions)
  })
}

export { mask }
