function email() {
  const regExp = new RegExp(
    "^[\\w!#$%&'*+\\-/=?^`{|}~]+(?:\\.[\\w!#$%&'*+\\-/=?^`{|}~]+)*@(?:([a-z0-9][a-z0-9-]{0,62}[a-z0-9]|[a-z])\\.)+[a-z]{2,11}|\\d{1,3}(?:\\.\\d{1,3}){3}(?::\\d{1,11})?$",
    "i"
  )

  const isEmpty = this.$input.value === ""

  if (isEmpty) {
    this.isValid = false
    this.$error.innerText = this.$input.dataset.empty

    return
  }

  if (!regExp.test(this.$input.value)) {
    this.isValid = false
    this.$error.innerText = this.$input.dataset.format

    return
  }

  this.isValid = true
}

export { email }
