function select() {
   
  
    const isEmpty = this.$input.value === ""
  
    if (isEmpty) {
      this.isValid = false
      this.$error.innerText = this.$input.dataset.empty
  
      return
    }
  

  
    this.isValid = true
  }
  
  export { select }