export function submit(event) {
  event.preventDefault()
  this.isValid = true

  // === Validation form
  this.validate()

  if (this.isValid) {
    // Disable the submit button
    const submitBtn = event.submitter
    submitBtn.disabled = true

    /*
     * If form is valid send request with ajax
     */
    this.sendXML({
      onSuccess: (data) => {
        const thankYouModal = document.querySelector("#thank_you_modal")
        const prevModal = thankYouModal.parentNode.querySelectorAll(".modal")[0]

        prevModal.classList.remove("show")
        thankYouModal.classList.add("show")

        console.log("Fetch is success")
        console.log(data)

        this.reset()

        // Re-enable the submit button
        submitBtn.disabled = false
      },
      onError: (data) => {
        console.log("Fetch with error")
        console.log(data)

        // Re-enable the submit button
        submitBtn.disabled = false
      }
    })
  } else {
    console.log("Invalid Fields", this.invalidFields)
  }

  /*
   * If form is valid send request with fetch
   */
  // this.sendFetch({
  //   onSuccess: (data) => {
  //     console.log("Fetch is success")
  //     console.log(data)

  //     this.reset()
  //   },
  //   onError: (data) => {
  //     console.log("Fetch with error")
  //     console.log(data)
  //   }
  // })
}
