import { accordion } from "./components/accordion"
import { formValidation } from "./components/form-validation"
import { modal } from "./components/modal"
import { select } from "./components/select"
import { tabs } from "./components/tabs"
import { header } from "./modules/header"

import Swiper, { Autoplay, Navigation, Pagination } from "swiper"
import { scrollUpBtn } from "./components/button-up"
import { calculatePrice } from "./components/calculate-price"
import { orderCallBtn } from "./components/call-btn"
import { cardsSlider } from "./components/cards-slider"
import { carousel } from "./components/carousel"
import { headerDropdown } from "./components/dropdown-menu"
import { gallery } from "./components/gallery"
import { map } from "./components/map"
import { mask } from "./components/mask"
import { ModalCookieSubmition } from "./components/modal-cookies"
import { ratingCounter } from "./components/rating-counter"
import { showAddress } from "./components/showAddress"
import { sliders } from "./components/swiper"
import { scrollSmoothTabs } from "./components/tabs-smooth-scroll"
import { navigation } from "./modules/navigaton"
import { setCurrentYear } from "./utils/set-current-year"

Swiper.use([Navigation, Pagination, Autoplay])

window.addEventListener("load", () => {
  // Show hidden elements
  document.querySelectorAll(".important_none")?.forEach(($el) => $el.classList.remove("important_none"))

  // Cookies
  new ModalCookieSubmition({
    container: ".js-cookies",
    closeTriggerSelector: ".js-cookies-close"
  })

  // ===== Components
  // All selects
  const selectsInstances = select()

  // All accordions
  const accordionsInstances = accordion()

  // Tabs
  const tabsInstance = tabs()

  // Forms
  const formsInstances = formValidation()

  // Modals
  const modalsInstance = modal()

  // Cards slider
  const sliderInstance = cardsSlider()

  // Slider
  sliders()
  // Fslightbox
  gallery()
  // Set current year in footer
  setCurrentYear()
  // Companies carousel
  carousel()

  // ===== Components end

  // ===== Modules
  const scrollUpBtnInstance = scrollUpBtn()

  const ratingCounterInstance = ratingCounter()

  const calculatePriceInstance = calculatePrice({ selectsInstances })

  const orderCallBtnInstance = orderCallBtn()

  const scrollSmoothTabsInstance = scrollSmoothTabs()

  if (window.innerWidth >= 1200) {
    const headerDropdownInstance = headerDropdown()
  }

  const maskInstance = mask()

  const mapInstance = map()

  // Nav
  const navigationInstance = navigation()

  const showAddressInstance = showAddress()

  // Header
  const headerInstance = header()
  // ===== Modules end
})
