import { minmaxLength } from "../lib/minmax-length"

function tel() {
  const regExp = new RegExp("[^0-9() +-]")
  const isEmpty = this.$input.value !== ""
  const { isMinMaxLengthValid } = minmaxLength(this.$input)

  if (!isEmpty) {
    this.isValid = false
    this.$error.innerText = this.$input.dataset.empty
    return
  }

  if (this.$input.value.match(regExp)) {
    this.isValid = false
    this.$error.innerText = this.$input.dataset.number
    return
  }

  if (!isMinMaxLengthValid) {
    this.isValid = false
    this.$error.innerText = this.$input.dataset.length
    return
  }

  this.isValid = true
}

export { tel }
