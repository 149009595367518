const showAddress = () => {
  const $btn = document.querySelector(".js-address-btn")
  const $dropdown = document.querySelector(".js-address-dropdown")
  const $btnScroll = document.querySelector("[data-scroll]")

  let isOpened = false

  if (!$btn) return {}

  const showAddress = () => {
    $dropdown.classList.add("active")

    isOpened = true
  }

  const hideAddress = () => {
    $dropdown.classList.remove("active")

    isOpened = false
  }

  const triggerClickHandle = () => {
    if (isOpened) {
      hideAddress()
    } else {
      showAddress()
    }
  }

  document.addEventListener("keydown", (event) => {
    if ("Tab" == event.key || "Escape" == event.key) {
      hideAddress()
    }
  })

  window.addEventListener("click", (event) => {
    if ((!$dropdown.contains(event.target) && !$btn.contains(event.target)) || $btnScroll.contains(event.target)) {
      hideAddress()
    }
  })

  $btn.addEventListener("click", triggerClickHandle)
}

export { showAddress }
