const ratingCounter = () => {
  const dataCounts = document?.querySelectorAll("[data-count]")

  if (dataCounts) {
    dataCounts.forEach((dataCount) => {
      let count = 0
      const targetCount = parseInt(dataCount.dataset.count, 10) || 0
      const interval = parseInt(dataCount.dataset.interval, 10) || 100

      const intervalId = setInterval(updateCounter, interval)
      
      function updateCounter() {
        if (count < targetCount) {
          const countValue = dataCount.querySelector(".count-value")
          const currentText = countValue.innerText
          const symbol = currentText.replace(/\d+/g, "")

          count++
          countValue.innerText = count + symbol
        } else {
          clearInterval(intervalId)
        }
      }

      updateCounter()
    })
  }
}

export { ratingCounter }

