export class CookiesRoot {
  getAll() {
    return document.cookie.split("; ").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=")
      acc[key] = value
      return acc
    }, {})
  }

  getByName(name) {
    return this.getAll()[name]
  }

  set(key, value, options = {}) {
    const config = Object.entries(options).reduce((acc, [key, value]) => {
      return `${acc}; ${key}=${value}`
    }, "")

    document.cookie = `${key}=${value}; ${config}`
  }
}

export const Cookies = new CookiesRoot()
