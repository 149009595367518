import { getElementOffset } from "../utils/helpers"

const scrollSmoothTabs = () => {
  const $tabsButtons = document.querySelectorAll(".js-tab-btn")
  const $servicesTabs = document.querySelector("#services-tabs")

  const header = document.querySelector(".header")
  const headerHeight = header ? header.offsetHeight : 0

  $tabsButtons.forEach(($btn) => {
    $btn.addEventListener("click", () => {
      window.scrollTo({
        top: getElementOffset($servicesTabs).dom.top - headerHeight,
        left: 0,
        behavior: "smooth"
      })
    })
  })
}

export { scrollSmoothTabs }
