const cardsSlider = () => {
  window.onload = () => {
    const sliderImagesBox = document.querySelectorAll(".response")
    const sliderBtnPrev = document.querySelector(".js-slider-btn")

    sliderImagesBox.forEach((el) => {
      const imageNodes = el.querySelectorAll(".response__item:not(.hide)")
      const arrIndexes = [] // Index array
      ;(() => {
        // The loop that added values to the arrIndexes array for the first time
        let start = 0
        while (imageNodes.length > start) {
          arrIndexes.push(start++)
        }
      })()

      let items = document.querySelectorAll(".response__item")
      items.forEach((itemCard) => {
        for (let i = 0; i < itemCard.children.length; i++) {
          if (itemCard.children[i].offsetHeight > 100) {
            itemCard.classList.add("hide-text")

            const btn = document.createElement("a")
            btn.classList.add("js-slider-btn-text")
            btn.classList.add("response__btn")
            btn.innerText = "Показати більше"
            btn.setAttribute("href", "#")
            btn.setAttribute("arial-label", "show more response text")
            itemCard.append(btn)
          }
        }
      })

      const setIndex = (arr) => {
        for (let i = 0; i < imageNodes.length; i++) {
          imageNodes[i].dataset.slide = arr[i] // Set indexes
        }
      }

      const sliderBtnShowText = el.querySelectorAll(".js-slider-btn-text")

      sliderBtnShowText.forEach((item) => {
        item.addEventListener("click", (event) => {
          event.preventDefault()

          if (item.innerText === "Приховати") {
            item.closest(".response__item").classList.add("hide-text")
            item.classList.remove("active")
            item.innerText = "Показати більше"
          } else {
            item.closest(".response__item").classList.remove("hide-text")
            item.innerText = "Приховати"
            item.classList.add("active")
          }
        })
      })

      sliderBtnPrev.addEventListener("click", () => {
        arrIndexes.unshift(arrIndexes.pop())
        setIndex(arrIndexes)

        sliderBtnShowText.forEach((item) => {
          item.closest(".response__item").classList.add("hide-text")
          item.innerText = "Показати більше"
          item.classList.remove("active")
        })
      })
      setIndex(arrIndexes) // The first indexes addition
    })
  }
}

cardsSlider()

export { cardsSlider }
