export const tabs = () => {
  const tabs = document.querySelectorAll("[data-value]")
  const tabsItems = document.querySelectorAll("[data-tab]")

  tabs.forEach(($tab) => {
    $tab.addEventListener("click", (event) => {
      const target = event.target

      tabs.forEach(($tab) => {
        $tab.classList.remove("active")
      })

      target.classList.add("active")

      tabsItems.forEach(($tabsItems, i) => {
        $tabsItems.classList.remove("active")

        if (target.dataset.value == $tabsItems.dataset.tab) {
          $tabsItems.classList.add("active")
        }
      })
    })
  })
}
