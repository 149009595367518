import "fslightbox"

const gallery = () => {
  const certificateItems = document.querySelectorAll(".contents img")

  certificateItems.forEach((item) => {
    item.closest("a")?.setAttribute("data-fslightbox", "")
  })

  refreshFsLightbox()
}

export { gallery }
