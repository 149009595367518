import { getElementOffset } from "../utils/helpers"

const headerDropdown = () => {
  const headerDropdown = document.querySelector(".nav__footer-dropdown")
  const headerDropdownWrraper = document.querySelector(".nav__footer-dropdown__wrapper")
  const headerDropdownItems = document.querySelectorAll("[data-enter]")
  const headerDropdownListItems = document.querySelectorAll("[data-dropdown]")
  const header = document.querySelector(".header")
  const dataDropdownHeaderBtn = document.querySelector("[data-subnavlist]")

  const headerHeight = header ? header.offsetHeight : 0

  const cordinatsItem = function (target) {
    const getCoordinates = target.getBoundingClientRect()
    let targetPosition = {
      top: window.screenY + getCoordinates.top,
      left: window.screenX + getCoordinates.left,
      right: window.screenX + getCoordinates.right,
      bottom: window.screenY + getCoordinates.bottom
    }

    return targetPosition
  }

  const windowPosition = {
    top: window.screenY,
    left: window.screenX,
    right: window.screenX + document.documentElement.clientWidth,
    bottom: window.screenY + document.documentElement.clientHeight
  }

  let dropdownIsOpen = false
  let dropdownListIsOpen = false

  const dataDropdownHeaderBtnTrigger = () => {
    if (!dropdownIsOpen) {
      dataDropdownHeaderBtn.classList.add("active")
      headerDropdownWrraper.style.opacity = "1"
      headerDropdownWrraper.style.visibility = "visible"

      dropdownIsOpen = true
    }
  }

  dataDropdownHeaderBtn.addEventListener("mouseover", dataDropdownHeaderBtnTrigger)

  window.addEventListener("resize", (event) => {
    if (window.innerWidth >= 1200) {
      dataDropdownHeaderBtn.removeEventListener("mouseover", dataDropdownHeaderBtnTrigger)
    } else {
      dataDropdownHeaderBtn.addEventListener("mouseover", dataDropdownHeaderBtnTrigger)
    }
  })

  window.addEventListener("click", (event) => {
    if (window.innerWidth >= 1200) {
      if (!headerDropdownWrraper.contains(event.target)) {
        headerDropdownWrraperMouseLeave()
      }
    }
  })

  document.addEventListener("keydown", (event) => {
    if ("Tab" == event.key || "Escape" == event.key) {
      headerDropdownWrraperMouseLeave()
    }
  })

  headerDropdownWrraper.addEventListener("mouseover", () => {
    if (dropdownIsOpen) {
      headerDropdownWrraper.style.opacity = "1"
      headerDropdownWrraper.style.visibility = "visible"
      dataDropdownHeaderBtn.removeEventListener("mouseover", dataDropdownHeaderBtnTrigger)
    }
  })

  const headerDropdownWrraperMouseLeave = () => {
    if (dropdownIsOpen) {
      headerDropdownWrraper.style.opacity = "0"
      headerDropdownWrraper.style.visibility = "hidden"

      dropdownIsOpen = false
      dataDropdownHeaderBtn.addEventListener("mouseover", dataDropdownHeaderBtnTrigger)
      dataDropdownHeaderBtn.classList.remove("active")
    }

    if (dropdownListIsOpen) {
      headerDropdownListItems.forEach(($item) => {
        $item.style.opacity = "0"
        $item.style.visibility = "hidden"
      })
      dropdownListIsOpen = false
    }
  }

  if (window.innerWidth >= 1200) {
    headerDropdownWrraper.addEventListener("mouseleave", headerDropdownWrraperMouseLeave)
  }

  const setheaderDropdownHeight = () => {
    if (headerDropdown.offsetHeight <= 612) {
      headerDropdown.style.maxHeight = `${document.documentElement.clientHeight - headerHeight}px`
    }

    headerDropdownListItems.forEach(($item) => {
      $item.style.maxHeight = `${document.documentElement.clientHeight - headerHeight}px`
    })
  }

  setheaderDropdownHeight()

  const headerDropdownItemTrigger = () => {
    dataDropdownHeaderBtn.classList.add("active")

    const target = event.currentTarget
    headerDropdownListItems.forEach(($item) => {
      $item.addEventListener("mouseenter", (event) => {
        const target = event.target
        $item.style.visibility = "visible"
        $item.style.opacity = "1"
      })

      $item.style.visibility = "hidden"
      $item.style.opacity = "0"

      if (cordinatsItem($item).bottom < windowPosition.bottom) {
        if (target.dataset.enter == $item.dataset.dropdown) {
          $item.style.top = `${cordinatsItem(target).top - window.screenY}px`
          $item.style.left = `${cordinatsItem(target).right - window.screenX}px`
          $item.style.visibility = "visible"
          $item.style.opacity = "1"
          $item.style.bottom = "auto"

          dropdownListIsOpen = true
        }
      }

      if (target.dataset.enter == $item.dataset.dropdown) {
        if (cordinatsItem($item).bottom > windowPosition.bottom) {
          $item.style.top = "auto"
          $item.style.bottom = `${windowPosition.bottom - cordinatsItem(target).top - target.offsetHeight}px`
          $item.style.left = `${cordinatsItem(target).right - window.screenX}px`
          dropdownListIsOpen = true
        }

        dropdownIsOpen = true
      }
    })
  }

  headerDropdownItems.forEach(($headerDropdownItem) => {
    if (!dropdownIsOpen) {
      $headerDropdownItem.addEventListener("mouseover", headerDropdownItemTrigger)
    } else {
      $headerDropdownItem.removeEventListener("mouseover", headerDropdownItemTrigger)
    }
  })

  const headerDropdownListItemsTriggerOpen = ($item) => {
    headerDropdownWrraper.style.opacity = "1"
    headerDropdownWrraper.style.visibility = "visible"

    dataDropdownHeaderBtn.classList.add("active")
  }

  let headerDropdownListItemsTriggerClose = ($item) => {
    headerDropdownWrraper.style.opacity = "0"
    headerDropdownWrraper.style.visibility = "hidden"
    dataDropdownHeaderBtn.classList.remove("active")

    headerDropdownListItems.forEach(($item) => {
      $item.style.visibility = "hidden"
      $item.style.opacity = "0"
    })
  }

  if (!dropdownIsOpen) {
    headerDropdownListItems.forEach(($item) => {
      $item.addEventListener("mouseover", headerDropdownListItemsTriggerOpen)

      dropdownIsOpen = true
    })
  }

  if (dropdownIsOpen) {
    headerDropdownListItems.forEach(($item) => {
      $item.addEventListener("mouseleave", headerDropdownListItemsTriggerClose)

      dropdownIsOpen = false
    })
  }
}

export { headerDropdown }
