import { getElementOffset } from "../utils/helpers"

const orderCallBtn = () => {
  const anchors = document.querySelectorAll("[data-scroll]")

  for (let anchor of anchors) {
    anchor.addEventListener("click", function (event) {
      event.preventDefault()

      const blockID = anchor.dataset.scroll
      const block = document.querySelector(`#${blockID}`)
      const header = document.querySelector(".header")

      if (block) {
        const blockOffset = getElementOffset(block)
        const headerHeight = header ? header.offsetHeight : 0

        window.scrollTo({
          top: blockOffset.dom.top - headerHeight,
          behavior: "smooth"
        })
      }
    })
  }
}

export { orderCallBtn }
